import React from 'react'

import Layout from '../layouts/layout'
import SEO from '../components/seo'
import TitleHeader from '../components/titleheader'
import SecondaryNav from '../components/secondarynav'
import MBBoardOfDirectors from '../components/mbboardofdirectors.js'

import headerimage from '../assets/images/whoweare/Corprate_WhoWeAre_BoardofDirectors-A_03.gif'
import mbheaderimage from '../assets/images/whoweare/Corprate_WhoWeAre_BoardofDirectors-MB-A.gif'
import { trackPageData, getPageDetails } from '../analytics'
class BoardOfDirectors extends React.PureComponent {
  constructor(props) {
    super(props)

    let dataManager = {
      pageDetails: {},
    }
    if (typeof window !== `undefined`) {
      if (window.dataManager) {
        dataManager = window.dataManager
      }
    }
    dataManager.pageDetails = getPageDetails()
  }

  componentDidMount() {
    trackPageData()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Board of Directors | The Children's Place Corporate Website"
          description="Learn more about The Children's Place Board of Directors on The Children's Place Corporate Website."
        />

        {/* Page Title & Header Image */}
        <div class="row mb-3">
          <div class="col-12">
            <TitleHeader
              image={headerimage}
              mbheaderimage={mbheaderimage}
              title="WHO WE ARE"
            />
          </div>
        </div>
        {/* End Page Title & Header Image */}

        <SecondaryNav
          active="Board of Directors"
          name1="About Us"
          link1="/about-us"
          name2="Senior Leadership"
          link2="/senior-leadership"
          name3="Board of Directors"
          link3="/board-of-directors"
          name4="International Franchises"
          link4="/international-franchises"
        />

        <div class="firstgroup d-md-block" id="seniorleadershipcolapsegroup">
          {/* START Content for text Section1 */}
          <div class="row mb-3 option-list">
            <div class="col-lg-12 col-md-12 col-sm-12 blue_line">
              <h5
                class="pl-1 pr-1"
                style={{
                  display: `inline`,
                }}
              >
                TURKI SALEH A. ALRAJHI
              </h5>{' '}
              <span class="the_line"> | </span>
              <p
                class="pl-1 pr-1"
                style={{
                  display: `inline`,
                }}
              >
                Chairman of the Board; Human Capital and Compensation Committee
              </p>
              {/* content */}
              <div class="bod-live-text">
                Mr. AlRajhi serves as the Chairman of the Board of the Company.
                He is also the Chairman and Chief Executive Officer of Mithaq
                Holding Company and Managing Director of Mithaq Capital SPC,
                where he is responsible for drafting and executing the overall
                strategy, which includes making the capital allocation
                decisions, the selection and compensation of managers, and
                writing the annual letter to shareholders. Mr. AlRajhi holds a
                Bachelor of Finance and Banking from Dar Al Uloom University in
                the Kingdom of Saudi Arabia and completed the Value Investing
                Program from Columbia Business School in the United States of
                America.
              </div>
              {/* end content       */}
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 blue_line">
              <h5
                class="pl-1 pr-1"
                style={{
                  display: `inline`,
                }}
              >
                MUHAMMAD ASIF SEEMAB
              </h5>{' '}
              <span class="the_line"> | </span>
              <p
                class="pl-1 pr-1"
                style={{
                  display: `inline`,
                }}
              >
                Vice-Chairman of the Board; Corporate Responsibility,
                Sustainability &amp; Governance Committee (Chair); Human Capital
                and Compensation Committee (Chair)
              </p>
              {/* content */}
              <div class="bod-live-text">
                Mr. Seemab serves as the Vice-Chairman of the Board of the
                Company. He also serves as Managing Director of Mithaq Holding
                Company and Mithaq Capital SPC, where he is responsible for
                executing and managing the company's investments, which include
                public equity, private equity, real estate, and income-producing
                assets in local and international markets. Mr. Seemab holds a
                Bachelor of Commerce from Hailey College of Commerce at the
                University of the Punjab and is a Chartered Accountant and an
                associate member of The Institute of Chartered Accountants of
                Pakistan.
              </div>
              {/* end content       */}
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 blue_line">
              <h5
                class="pl-1 pr-1"
                style={{
                  display: `inline`,
                }}
              >
                HUSSAN ARSHAD
              </h5>{' '}
              <span class="the_line"> | </span>
              <p
                class="pl-1 pr-1"
                style={{
                  display: `inline`,
                }}
              >
                Independent Director; Audit Committee (Chair); Corporate
                Responsibility, Sustainability &amp; Governance Committee
              </p>
              {/* content */}
              <div class="bod-live-text">
                Mr. Arshad serves as the Group Senior Manager at DP World, where
                he is responsible for internal audits, internal controls, and
                risk management. Prior to that, he held positions in various
                organizations, including Ernst &amp; Young, and as a Senior
                Manager of Operational Audits with Bell Canada Enterprise. Mr.
                Arshad is a member of the Chartered Professional Accounts of
                Canada, The Institute of Chartered Accountants in England and
                Wales, The Institute of Chartered Accountants of Pakistan, and
                The Institute of Internal Auditors in the United States of
                America.
              </div>
              {/* end content       */}
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 blue_line">
              <h5
                class="pl-1 pr-1"
                style={{
                  display: `inline`,
                }}
              >
                DOUGLAS EDWARDS
              </h5>{' '}
              <span class="the_line"> | </span>
              <p
                class="pl-1 pr-1"
                style={{
                  display: `inline`,
                }}
              >
                Independent Director; Audit Committee; Corporate Responsibility,
                Sustainability &amp; Governance Committee
              </p>
              {/* content */}
              <div class="bod-live-text">
                Mr. Edwards served as the Executive Vice President and Interim
                General Counsel of Wells Fargo &amp; Company, where he was
                responsible for the Global Commercial and Securities Division.
                In 2005, he was recognized as North Carolina's “Best Corporate
                Lawyer” in its annual Legal Elite selections and was named a
                North Carolina Super Lawyer in 2007, 2008, and 2009. Mr. Edwards
                holds a Bachelor of Arts from the University of Virginia, a
                Master of Arts in History from the University of Kentucky, and a
                Juris Doctorate of Law, cum laude, from the University of
                Buffalo in the United States of America.
              </div>
              {/* end content       */}
            </div>
          </div>
          {/* END Content for text Section1 */}

          {/* START Content for text Section2 */}
          <div class="row mb-3 option-list">
            <div class="col-lg-12 col-md-12 col-sm-12 ">
              <h5
                class="pl-1 pr-1"
                style={{
                  display: `inline`,
                }}
              >
                MUHAMMAD UMAIR
              </h5>{' '}
              <span class="the_line"> | </span>{' '}
              <p
                class="pl-1 pr-1"
                style={{
                  display: `inline`,
                }}
              >
                Director; President and Interim CEO
              </p>
              {/* content */}
              <div class="bod-live-text">
                Mr. Umair serves as the President and Interim Chief Executive
                Officer of the Company. Prior to his appointment as President
                and Interim Chief Executive Officer, Mr. Umair was a Senior
                Advisor for Origin Funding Partners, a global trade finance
                fund, where he was responsible for credit, recovery, and due
                diligence. Prior to joining Origin Funding Partners, Mr. Umair
                held positions in various organizations, including Head of
                Advisory at Armacom and Senior Auditor at Ernst &amp; Young. He
                is a Chartered Accountant and associate member of the Institute
                of Chartered Accountants of Pakistan and the Institute of
                Chartered Accountants in England and Wales. Mr. Umair has more
                than 17 years of financial and commercial experience, including
                investment management, corporate advisory, operational and
                financial due diligence and audit.
              </div>
              {/* end content       */}
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
      </Layout>
    )
  }
}

export default BoardOfDirectors
